












































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { logisticServices } from "@/services/logistic.service";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { cashManagementServices } from "@/services/cashmanagement.service";
import moment from "moment";
import { settingsServices } from "@/services/settings.service";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { masterServices } from "@/services/master.service";
import {
  DataListBankTransfer,
  DataListCashOut,
} from "@/models/interface/cashManagement.interface";
import { RequestQueryParams } from "@/models/class/request-query-params.class";

@Component
export default class CashInOut extends Vue {
  currentPage = 1 as number;
  limit = 10;
  page = 0;
  dataList = [] as DataListCashOut[];
  totalData = 0 as number;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form = this.$form.createForm(this, { name: "cashInOut" });
  downloadParam = {
    companyName:
      this.$store.state.authStore.authData.companyName ||
      "PT. SATRIA PIRANTI PERKASA",
    branch: "ALL",
    documentNo: "ALL",
    cashDateFrom: "ALL",
    cashDateTo: "ALL",
    type: "ALL",
    bank: "ALL",
    status: "ALL",
    currency: "ALL",
  };
  searchData = "" as any;
  columnsTable = [
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branch",
      scopedSlots: { customRender: "isNull" },
      width: 150,
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      key: "type",
      scopedSlots: { customRender: "isNull" },
      width: 150,
    },
    {
      title: this.$t("lbl_date"),
      dataIndex: "date",
      key: "date",
      scopedSlots: { customRender: "isDate" },
      width: 200,
    },
    {
      title: this.$t("lbl_document_no"),
      dataIndex: "documentNo",
      key: "documentNo",
      scopedSlots: { customRender: "isNull" },
      width: 150,
    },
    {
      title: this.$t("lbl_bank_name"),
      dataIndex: "bankName",
      key: "bankName",
      scopedSlots: { customRender: "isNull" },
      width: 150,
    },
    {
      title: this.$t("lbl_currency"),
      dataIndex: "currencyCode",
      key: "currency",
      scopedSlots: { customRender: "isNull" },
      width: 200,
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "amount",
      key: "amount",
      scopedSlots: { customRender: "isCurrency" },
      width: 200,
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      scopedSlots: { customRender: "isNull" },
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["view"],
      width: 120,
      align: "center",
    },
  ] as ColumnTableCustom;
  formRules = {
    cashDateFrom: {
      decorator: ["cashDateFrom"],
    },
    cashDateTo: {
      decorator: ["cashDateTo"],
    },
    branch: {
      decorator: ["branch"],
    },
    bank: {
      decorator: ["bank"],
    },
    documentNo: {
      decorator: ["documentNo"],
    },
    type: {
      decorator: ["type"],
    },
    currency: {
      decorator: ["currency"],
    },
    status: {
      decorator: ["status"],
    },
  };
  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
  dataBranch = [] as DataWarehouseBranch[];
  dataType = [] as any[];
  dataBank = [] as any[];
  dataDocumentNo = [] as DataListCashOut[];
  dataStatus = [] as any[];
  dataCurrency = [] as DataMasterCurrency[];
  loading = {
    branch: false as boolean,
    table: false as boolean,
    find: false as boolean,
    download: false as boolean,
    documentNo: false as boolean,
    currency: false as boolean,
    type: false as boolean,
    bank: false as boolean,
    status: false as boolean,
  };
  searchDropdown(value, type) {
    if (value) {
      switch (type) {
        case "branch":
          this.getBranch(value);
          break;
        case "documentNo":
          this.getListDocumentNumber(value);
          break;
        case "currency":
          this.getCurrency(value);
          break;
        case "bank":
          this.getListBank(value);
          break;
        case "type":
          this.getType(value);
          break;
        case "status":
          this.getStatus(value);
          break;
        default:
          break;
      }
    }
  }
  getListDocumentNumber(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value) params.search = `documentNo~*${value}*`;
    this.loading.documentNo = true;
    cashManagementServices
      .listCashOut(params)
      .then(res => {
        this.dataDocumentNo = res.data.filter(item => item.documentNo);
      })
      .finally(() => (this.loading.documentNo = false));
  }
  getListBank(value) {
    let params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      sort: `createdDate:desc`,
    };
    this.loading.bank = true;
    if (value) {
      params.search = `bankAccName~*${value}*_OR_bankAccNumber~*${value}*_OR_bankName~*${value}*`;
    }
    masterServices
      .listCompanyBank(params)
      .then(res => {
        this.dataBank = res.data;
      })
      .finally(() => {
        this.loading.bank = false;
      });
  }
  getCurrency(value) {
    let params = {
      limit: 10,
      page: 0,
    } as RequestQueryParamsModel;
    this.loading.currency = true;
    if (value)
      params.search = `currencyCode~*${value}*_OR_description~*${value}`;
    return settingsServices
      .listOfMasterCurrency(params, "")
      .then(res => {
        this.dataCurrency = res.data;
      })
      .finally(() => (this.loading.currency = false));
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  responseViewTable(response) {
    this.$router.push(
      "/cash-management/transactions/cash-in-out/edit/" + response.data.id
    );
  }
  findData(page): void {
    this.form.validateFields((err, value) => {
      if (!err) {
        const params: RequestQueryParamsModel = {
          page: page ? this.page : 0,
          limit: this.limit,
          sorts: "createdDate:desc",
        };
        for (const key in this.downloadParam) {
          if (key != "companyName") {
            this.downloadParam[key] = "ALL";
          }
        }
        if (this.dynamicSearch(value)) {
          this.searchData = this.dynamicSearch(value);
          params.search = this.dynamicSearch(value);
        }
        this.getData(params);
      }
    });
  }
  getData(params) {
    this.loading.table = true;
    cashManagementServices
      .listCashOut(params)
      .then(res => {
        this.dataList = res.data.map(item => ({ ...item, key: item.id }));
        this.totalData = res.totalElements;
      })
      .finally(() => (this.loading.table = false));
  }
  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  assignSearch(key, value, operator): string {
    if (key === "branch" && value) {
      this.downloadParam.branch = this.dataBranch.find(item => item.id == value)
        ?.name as string;
      return operator + `branchWarehouse.secureId~${value}`;
    } else if (key === "currency" && value) {
      this.downloadParam.currency = this.dataCurrency.find(
        item => item.id == value
      )?.currencyCode as string;
      return operator + `currency.secureId~${value}`;
    } else if (key === "documentNo" && value) {
      this.downloadParam.documentNo = value;
      return operator + `documentNo~${value}`;
    } else if (key === "type" && value) {
      this.downloadParam.type = value;
      return operator + `type~*${value}*`;
    } else if (key === "status" && value) {
      this.downloadParam.status = value;
      return operator + `status~${value}`;
    } else if (key === "bank" && value) {
      this.downloadParam.bank =
        this.dataBank.find(item => item.id === value).bankName +
        " - " +
        this.dataBank.find(item => item.id === value).bankAccNumber;
      return operator + `companyBank.secureId~${value}`;
    } else if (key === "cashDateFrom" && value) {
      this.downloadParam.cashDateFrom =
        moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `transactionDate>=${moment(value)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .format()}`
      );
    } else if (key === "cashDateTo" && value) {
      this.downloadParam.cashDateTo = moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `transactionDate<=${moment(value)
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .format()}`
      );
    } else return "";
  }
  clearItems(): void {
    this.form.resetFields();
  }
  CreateNew(): void {
    this.$router.push("/cash-management/transactions/cash-in-out/create");
  }
  download() {
    let params = {
      params: `${this.downloadParam.companyName},${this.downloadParam.branch},${this.downloadParam.documentNo},${this.downloadParam.cashDateFrom},${this.downloadParam.cashDateTo},${this.downloadParam.type},${this.downloadParam.bank},${this.downloadParam.currency},${this.downloadParam.status}`,
    } as RequestQueryParamsModel;
    if (this.searchData) {
      params.search = this.searchData
        .replace("branchWarehouse.secureId", "branchId")
        .replace("companyBank.secureId", "bankId")
        .replace("currency.secureId", "currencyId");
    }
    this.loading.download = true;
    cashManagementServices
      .downloadCash(params)
      .then(res => {
        if (res) {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "cash_in_out.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .finally(() => {
        this.loading.download = false;
      });
  }
  mounted() {
    this.getBranch("");
    this.getCurrency("");
    this.getListBank("");
    this.getStatus("");
    this.getType("");
    this.getListDocumentNumber("");
  }
  getStatus(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value) params.search = `status~*${value}*`;
    this.loading.status = true;
    cashManagementServices
      .listCashStatus(params)
      .then(res => {
        this.dataStatus = res;
      })
      .finally(() => (this.loading.status = false));
  }
  getType(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value) params.search = `type~*${value}*`;
    this.loading.type = true;
    cashManagementServices
      .listCashType(params)
      .then(res => {
        this.dataType = res;
      })
      .finally(() => (this.loading.type = false));
  }
  getBranch(value) {
    const params = new RequestQueryParams();
    if (value)
      params.search = `name~*${value}*_OR_code~*${value}*_OR_address~*${value}`;
    this.loading.branch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.branch = false));
  }
  handleClickColumn(record, objColumnNameValue) {
    this.$router.push(
      "/generaljournal/journal/detail/" + record.journalNumberId
    );
  }
  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 0;
    this.currentPage = 1;
    this.findData(true);
  }
  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page - 1;
    this.currentPage = response.page;
    this.findData(true);
  }
}
